import React, { useEffect } from 'react';
import Navbar from './components/NavBar';
import Footer from './components/Footer';
import emailjs from 'emailjs-com';
import './index.css'

const Contact = () => {

  useEffect(() => {
    emailjs.init("b71Hqtoi-JLoD-jeF")
  },[]);

  function sendEmail(e) {
    e.preventDefault();
  
    const firstName = document.getElementById("first_name").value.trim();
    const lastName = document.getElementById("last_name").value.trim();
    const email = document.getElementById("email_id").value.trim();
    const phoneNumber = document.getElementById("phone_number").value.trim();
    const message = document.getElementById("message").value.trim();
  
    if (!firstName || !lastName || !email || !phoneNumber || !message) {
      alert("Please fill in all fields before submitting the form.");
      return;
    }
  
    const params = {
      first_name: firstName, 
      last_name: lastName, 
      email_id: email, 
      phone_number: phoneNumber, 
      message: message, 
    };
  
    emailjs.send('service_sf9ws34', 'template_e5ropka', params)
      .then(function(res){
        alert("Thank you for contacting us! We will get in touch soon!! " + res.status);
      })
      .catch((error) => {
        console.log(error.text);
      });
  }



  return (
    <>
    <div className="background-colour">
    <Navbar />

<div className='max-w-6xl pt-24 mx-auto flex flex-col justify-center'>
    <div className="md:py-16 py-8 px-8 flex flex-col">
      <div className="rounded-lg container mx-auto">
        <h2 className="md:text-5xl text-4xl font-light fontColour1">Contact Us</h2>
        <p className="text-gray-600 text-xl md:text-2xl mb-2 md:mb-4 font-light">Get in touch</p>
        <div className='flex justify-center mt-12'>
        <form 
          onSubmit={sendEmail}
          className="space-y-4 w-5/6">
          <div className="flex flex-col md:flex-row md:space-x-4">
            <div className="flex-1">
              <label className="text-gray-600 border-black block font-extralight text-xl	inter">First Name</label>
              <input
                id= "first_name"
                type="text"
                className="font-normal background-colour border-black	h-12	text-base inter w-full px-4 py-2 mt-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-400"
                placeholder="First Name"
              />
            </div>
            <div className="flex-1">
              <label className="text-gray-600 border-black	block font-extralight text-xl	inter">Last Name</label>
              <input
                id= "last_name"
                type="text"
                className="font-normal h-12 background-colour border-black	text-base inter w-full px-4 py-2 mt-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-400"
                placeholder="Last Name"
              />
            </div>
          </div>
          <div>
            <label className="text-gray-600 block font-extralight text-xl	inter">Email</label>
            <input
              id= "email_id"
              type="email"
              className="font-normal background-colour h-12 text-base inter w-full border-black	px-4 py-2 mt-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-400"
              placeholder="Email"
            />
          </div>
          <div>
            <label className="text-gray-600 block font-extralight text-xl	inter">Phone Number</label>
            <input
              id= "phone_number"
              type="text"
              className="font-normal h-12 background-colour text-base inter w-full px-4 py-2 mt-2 border-black border rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-400"
              placeholder="Phone number"
            />
          </div>
          <div>
            <label className="text-gray-600 block font-extralight text-xl	inter">Message</label>
            <textarea
              id= "message"
              className="font-normal background-colour border-black	text-base inter w-full px-4 py-2 mt-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-400"
              placeholder="Message"
              rows="4"
            ></textarea>
          </div>
          <button
            type="submit"
            className="font-normal text-base inter w-full py-2 mt-4 bg-gray-800 text-white rounded-lg hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-400"
          >
            Send
          </button>
        </form>
        </div>
      </div>
    </div>
</div>

    <Footer />

    </div>
    </>
  );
};

export default Contact;