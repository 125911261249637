import React, { useEffect, useState } from 'react';
import Navbar from './components/NavBar';
import Footer from './components/Footer';
import { useInView } from 'react-intersection-observer';
import "./index.css"
import 'animate.css';

import aboutUsImage from '../src/images/aboutus.png'
import delivery from '../src/images/delivery.png'
import whyUs1 from '../src/images/whyUs1.png'
import whyUs2 from '../src/images/whyUs2.png'
import whyUs3 from '../src/images/whyUs3.png'
import car1 from '../src/images/car1.png'
import car2 from '../src/images/car2.png'
import car3 from '../src/images/car3.png'

import heroImage1 from '../src/images/slides1.jpg'
import heroImage2 from '../src/images/slides2.jpg'
import heroImage3 from '../src/images/slides3.jpg'
// import heroImage4 from '../src/images/slides4.jpg'
import heroImage5 from '../src/images/slides5.jpg'
import heroImage6 from '../src/images/heroimage.png'

function Home() {

  const [fadeOut, setFadeOut] = useState(false);

  const { ref: aboutRef, inView: aboutInView } = useInView({ triggerOnce: true, threshold: 0.1 });
  const { ref: whyUsRef, inView: whyUsInView } = useInView({ triggerOnce: true, threshold: 0.1 });
  const { ref: lineUpRef, inView: lineUpInView } = useInView({ triggerOnce: true, threshold: 0.1 });
  const { ref: deliveryRef, inView: deliveryRefInView } = useInView({ triggerOnce: true, threshold: 0.1 });
  const { ref: storeRef, inView: storeRefInView } = useInView({ triggerOnce: true, threshold: 0.1 });

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const heroImages = [heroImage1, heroImage2, heroImage3, heroImage5,];
  const [fade, setFade] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % heroImages.length);
    }, 4000); 

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setFadeOut(true); 
    }, 1000); 

    return () => clearTimeout(timer);
  }, []);


  return (
    <>
      <div className='background-colour'>

        {/* Hero Section */}
        <section className="relative h-screen bg-cover bg-center" 
                 style={{ backgroundImage: `url(${heroImages[currentImageIndex]})` }}>
          <div className={`absolute inset-0 z-10 transition-opacity ${fade ? 'opacity-0' : 'opacity-100'}`} />

          <div className="absolute top-0 left-0 w-full z-30">
            <Navbar />
          </div>
          
          <div className="absolute inset-0 bg-black bg-opacity-40 flex justify-start items-center z-20">
            <div className="text-left pl-[10%] text-white">
              <p className="text-lg inter md:text-3xl font-normal md:font-normal">Welcome to</p>
              <h1 className="text-4xl md:text-6xl noto font-semibold animate__animated animate__fadeInUp">Kouseki Auto Rental</h1>
              <p className="mt-2 text-lg inter font-normal md:font-normal md:text-2xl font-animation">Add color to your journey with our rental cars.</p>
              <a href="/contact">
                <button className="inter my-2 text-xl font-light w-36 md:w-44 h-14 md:h-16 border border-white rounded-2xl text-white mt-6 md:mt-12 relative overflow-hidden group">
                  <span className="relative text-base md:text-lg z-10">
                    Contact Us{' '}
                    <span className="inline-block transform transition-transform duration-300 ease-in-out group-hover:rotate-[360deg]">+</span>
                  </span>
                  <span className="absolute inset-0 bg-slate-900 transform translate-y-full transition-transform duration-300 ease-in-out"></span>
                </button>
              </a>
            </div>
          </div>
        </section>


<div className='max-w-6xl mx-auto flex flex-col justify-center animate__fadeInUp animate__delay-5s'>
      {/* About Us Section */}
    <section ref={aboutRef} className={`md:py-16 py-12 px-8 ${aboutInView ? 'animate__animated animate__fadeInUp' : 'opacity-0'}`}>
      <div className="container mx-auto">
      <h3 className="text-2xl font-light fontColour1 mb-1 md:mb-10">ABOUT US</h3>
      <div className="flex flex-col-reverse justify-between md:flex-row">
        <div className="w-full md:w-1/2 px-4">
          <h2 className="text-3xl md:text-4xl font-semibold text-gray-900 leading-tight my-4">
            Explore Vancouver <br /> with our Rental Cars
          </h2>
          <p className="text-lg mb-2">
            Experience the perfect blend of nature and city vibes!
          </p>
          <p className="text-lg">
            We help your Vancouver journey, whether you’re exploring the city, travelling, or planning a weekend getaway. It’s the perfect option for families, friends, and all types of travelers!
          </p>
        </div>

        <div className="w-full md:w-1/2 px-4 mt-8 md:mt-0">
          <img 
            src={aboutUsImage}
            alt="Family in a car" 
            className="rounded-lg shadow-lg" 
          />
        </div>
      </div>
      </div>
    </section>

      {/* Why Us Section */}
      <section ref={whyUsRef} className={`md:py-16 py-8 px-8 ${whyUsInView ? 'animate__animated animate__fadeInUp' : 'opacity-0'}`}>
      <div className="container mx-auto">
        <h2 className="md:text-5xl text-4xl font-light fontColour1 mb-10">WHY US?</h2>

        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          
          {/* First Column */}
          <div className="flex flex-col space-y-8 col-span-2 md:col-span-2 mx-8">

            <div className="relative">
              <img
                src={whyUs1} 
                alt="24/7 Pickup & Drop-Off and Chat Support"
                className="w-full h-auto object-cover rounded-full md:w-64 md:h-64"
              />
              <div className="absolute inset-0 flex flex-col text-left justify-center text-white bg-black bg-opacity-50 rounded-full p-6">
                <h3 className="text-2xl noto font-semibold">24/7 Pickup & Drop-Off and Chat Support</h3>
                <p className="text-sm leading-tight inter mt-2">We offer 24/7 pickup and drop-off options, along with live chat support for all your needs.</p>
                <span className="absolute bottom-0 right-4 text-8xl font-bold text-green-800 stroke">01</span>
              </div>
            </div>

            <div className="relative">
              <img
                src={whyUs3}
                alt="Free shuttle from YVR"
                className="w-full h-auto object-cover rounded-full md:w-64 md:h-64"
              />
              <div className="absolute text-left inset-0 flex flex-col justify-center text-white bg-black bg-opacity-50 rounded-full p-6">
                <h3 className="text-2xl noto font-semibold">Free shuttle from YVR</h3>
                <p className="text-sm leading-tight inter mt-2">Free shuttle service to and from Vancouver International Airport (YVR) with every rental.</p>
                <span className="absolute bottom-0 right-4 text-8xl font-bold text-green-800 stroke">03</span>
              </div>
            </div>
          </div>

          {/* Second Column */}
          <div className="flex flex-col justify-between col-span-2 md:col-span-2 md:mt-40 mx-8">

            <div className="relative mb-8 md:mb-0">
              <img
                src={whyUs2} 
                alt="Child seat rentals"
                className="w-full h-auto object-cover rounded-full md:w-64 md:h-64"
              />
              <div className="absolute text-left inset-0 flex flex-col justify-center text-white bg-black bg-opacity-50 rounded-full p-6">
                <h3 className="text-2xl noto font-semibold">Child seat rentals</h3>
                <p className="text-sm leading-tight inter mt-2">We have a wide range of child seats, accommodating all ages, available for rental with your car.</p>
                <span className="absolute bottom-0 right-4 text-8xl font-bold text-green-800 stroke">02</span>
              </div>
            </div>

            <a href="/about">          
              <button className="inter my-2 text-xl font-light w-48 h-16 border border-gray-800 rounded-2xl text-gray-800 mt-6 md:mt-12 relative overflow-hidden group hover:text-white">
                <span className="relative z-10">
                  View More{' '}
                  <span className="inline-block transform transition-transform duration-300 ease-in-out group-hover:rotate-[360deg]">
                    +
                  </span>
                </span>
                 <span className="absolute inset-0 bg-slate-900 transform translate-y-full transition-transform duration-300 ease-in-out group-hover:translate-y-0"></span>
              </button>
            </a>
          </div>
        </div>
      </div>
    </section>

      {/* Line Up Section */}
      <section ref={lineUpRef} className={`md:py-16 py-12 px-8 ${lineUpInView ? 'animate__animated animate__fadeInUp' : 'opacity-0'}`}>
      <div className="container mx-auto">
        <h4 className="md:text-5xl text-4xl font-light md-2 fontColour1">LINE UP</h4>
        <p className="text-gray-600 text-xl md:text-2xl mb-2 md:mb-4 font-light">Our Car Models</p>
        <p className="text-gray-600 font-normal leading-tight text-base md:text-lg w-96 px-4">
          We offer a variety of vehicles, from small compact SUVs to luxury cars and minivans, to suit all types of trips!
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">

          <div className="relative text-center">
            <img
              src={car1}
              alt="Compact Car"
              className="w-1/2 h-auto mx-auto object-cover"
            />
            <h5 className="text-2xl font-normal mt-4">Compact Car</h5>
            <hr className="border-gray-300 my-2" />
            <p className="text-base text-gray-600 mt-1">capacity: <span className="text-2xl font-normal">4 - 5</span></p>
            <hr className="border-gray-300 my-2" />
          </div>

          <div className="relative text-center">
            <img
              src={car2}
              alt="MPV"
              className="w-1/2 h-auto mx-auto object-cover"
            />
            <h5 className="text-2xl font-normal mt-4">MPV</h5>
            <hr className="border-gray-300 my-2" />
            <p className="text-base text-gray-600 mt-1">capacity: <span className="text-2xl font-normal">7 - 8</span></p>
            <hr className="border-gray-300 my-2" />
          </div>

          <div className="relative col-span-1 md:col-span-2 text-center">
            <img
              src={car3}
              alt="Luxury Car"
              className="md:w-1/4 w-1/2 h-auto mx-auto object-cover"
            />
            <h5 className="text-2xl font-normal mt-4">Standard SUV</h5>
            <hr className="border-gray-300 my-2" />
            <p className="text-base text-gray-600 mt-1">capacity: <span className="text-2xl font-normal">4 - 7</span></p>
            <hr className="border-gray-300 my-2" />
          </div>
        </div>

          <div className='flex justify-center'>
            <a href="/cars">         
             <button className="inter my-2 text-xl font-light w-48 h-16 px-6 border border-gray-800 rounded-2xl text-gray-800 mt-6 md:mt-12 relative overflow-hidden group hover:text-white">
              <span className="relative z-10">
                View More{' '}
                <span className="inline-block transform transition-transform duration-300 ease-in-out group-hover:rotate-[360deg]">
                  +
                </span>
              </span>
              <span className="absolute inset-0 bg-slate-900 transform translate-y-full transition-transform duration-300 ease-in-out group-hover:translate-y-0"></span>
             </button>
            </a>
          </div>
      </div>
    </section>

      {/* Delivery the car Section */}
    <section ref={deliveryRef} className={`md:py-16 py-12 px-8 ${deliveryRefInView ? 'animate__animated animate__fadeInUp' : 'opacity-0'}`}>
      <div className="container mx-auto">
      <h3 className="text-2xl font-light fontColour1 mb-1 md:mb-10">Delivery the Car</h3>
      <div className="flex flex-col justify-between md:flex-row">
        <div className="w-full px-4 mt-8 md:mt-0">
          <img 
            src={delivery}
            alt="Family in a car" 
            className="rounded-lg shadow-lg" 
          />
        </div>
        <div className=" px-4">
          <h6 className="text-3xl md:text-4xl font-semibold text-gray-900 leading-tight my-4">
            We deliver cars <br /> Anywhere in BC
          </h6>
          <p className="text-lg mb-2">
          At Kouseki Auto Rental, we deliver rental cars anywhere in British Columbia, even if you're not arriving at YVR. 
          Whether you're in Vancouver, the mountains, along the coast, or in any other city, we bring the car directly to you.
          </p>
          <p className="text-lg">
          Our service saves you time and effort—no need to visit a rental office. 
          Simply choose your location, and we’ll handle the rest!
          </p>
        </div>
      </div>
      </div>
    </section>

      {/* Store Section */}
  <section ref={storeRef} className={`md:py-16 py-12 px-8  ${storeRefInView ? 'animate__animated animate__fadeInUp' : 'opacity-0'}`}>
  <div className="container mx-auto">
    <h7 className="text-2xl mb-1 md:mb-10 font-light fontColour1">STORE</h7>

    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-start">
      {/* Map Section */}
      <div className="px-4 md:mt-8 mt-4 md:mt-0">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2607.9645881030065!2d-123.17563732323333!3d49.18225857137784!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54860b2f5ee05d3b%3A0x9621e6b4d4a7764f!2s4380%20Agar%20Dr%20%23214%2C%20Richmond%2C%20BC%20V7B%201A3!5e0!3m2!1sen!2sca!4v1726622937093!5m2!1sen!2sca"
          style={{ width: '100%', height: '350px', border: '0' }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Direction to the store"
        ></iframe>
      </div>

      <div className="">
        <h8 className="text-3xl md:text-4xl font-semibold text-gray-900 leading-tight mt-12 my-4">
          Kouseki Auto Rental Inc.
        </h8>
        <p className="text-lg mb-2">
          4380 Agar Drive #214 Richmond, BC <br />
          V7B 1A3, Canada
        </p>
        <div className="flex">
          <a href="/store">
          <button className="inter my-2 text-xl font-light w-48 h-16 px-6 border border-gray-800 rounded-2xl text-gray-800 mt-6 md:mt-12 relative overflow-hidden group hover:text-white">
            <span className="relative z-10">
              View Store{' '}
              <span className="inline-block transform transition-transform duration-300 ease-in-out group-hover:rotate-[360deg]">
                +
              </span>
            </span>
            <span className="absolute inset-0 bg-slate-900 transform translate-y-full transition-transform duration-300 ease-in-out group-hover:translate-y-0"></span>
          </button>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
</div>
      <Footer />
    </div>
    </>
  );
}

export default Home;
