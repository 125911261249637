import CheroletOrlando from '../src/images/cars/chevroletOrlando.png';
import ChevroletTrax from '../src/images/cars/chevroletTrax.png';
import ChryslerPacifica from '../src/images/cars/chryslerPacifica.png';
import FIAT500 from '../src/images/cars/fiat500.png';
import FordF150 from '../src/images/cars/fordF-150.png';
import HyundaiSantaFe from '../src/images/cars/hyundaiSantaFe.png';
import MazdaMazda6 from '../src/images/cars/mazdaMazda6.png';
import MercedesBenzCLAClass from '../src/images/cars/mercedesBenzCLA-Class.png';
import MercedesBenzGLCClass from '../src/images/cars/mercedesBenzGLC-Class.png';
import MercedesBenzMetris from '../src/images/cars/mercedesBenzMetris.png';
import PorsheMacan from '../src/images/cars/porscheMacan.png';
import VolkswagenTiguan from '../src/images/cars/volkswagenTiguan.png';
import Smartfortwo from '../src/images/cars/smartFortwo.png';
import FordMustang from '../src/images/cars/fordMustang.png';
import chryslerTownCountry from '../src/images/cars/chryslerTownCountry.png'

export const carsData = [
    { id: 1, 
      // name: "Cherolet Orlando 2013", 
      name: "Cherolet Orlando", 
      type: "Compact SUV", 
      imgSrc: CheroletOrlando},
    { id: 2, 
      // name: "Chevrolet Trax 2024", 
      name: "Chevrolet Trax", 
      type: "Compact SUV", 
      imgSrc: ChevroletTrax },
    // { id: 3, 
    //   // name: "Chevrolet Trax 2025", 
    //   name: "Chevrolet Trax", 
    //   type: "Compact SUV", 
    //   imgSrc: ChevroletTrax2025 },
    { id: 4, 
      // name: "Chrysler Pacifica 2022", 
      name: "Chrysler Pacifica", 
      type: "VAN", 
      imgSrc: ChryslerPacifica },
    { id: 5, 
      // name: "FIAT 500 2013", 
      name: "FIAT 500", 
      type: "Compact Car", 
      imgSrc: FIAT500 },
    { id: 6, 
      // name: "Ford F-150 2016", 
      name: "Ford F-150", 
      type: "Pick-up Truck", 
      imgSrc: FordF150 },
    { id: 7, 
      // name: "Hyundai Santa Fe 2023", 
      name: "Hyundai Santa Fe", 
      type: "Standard SUV", 
      imgSrc: HyundaiSantaFe }, 
    { id: 8, 
      name: "Chrysler Town & Country", 
      type: "VAN", 
      imgSrc: chryslerTownCountry },  
    { id: 9, 
      // name: "Mazda Mazda6 2018", 
      name: "Mazda Mazda6", 
      type: "Mid-size Car", 
      imgSrc: MazdaMazda6 },
    { id: 10, 
      // name: "Mercedes Benz CLA-Class 2020", 
      name: "Mercedes Benz CLA-Class", 
      type: "Premium Car", 
      imgSrc: MercedesBenzCLAClass },
    { id: 11, 
      // name: "Mercedes Benz GLC-Class 2017", 
      name: "Mercedes Benz GLC-Class", 
      type: "Standard SUV", 
      imgSrc: MercedesBenzGLCClass },
    { id: 12, 
      // name: "Mercedes Benz Metris 2018", 
      name: "Mercedes Benz Metris", 
      type: "VAN", 
      imgSrc: MercedesBenzMetris },
    { id: 13, 
      // name: "Porshe Macan 2016", 
      name: "Porshe Macan", 
      type: "Standard SUV", 
      imgSrc: PorsheMacan },    
    { id: 14, 
      // name: "Volkswagen Tiguan 2022", 
      name: "Volkswagen Tiguan", 
      type: "Standard SUV", 
      imgSrc: VolkswagenTiguan },     
    { id: 15, 
      // name: "Smart fortwo 2016", 
      name: "Smart fortwo", 
      type: "Compact Car", 
      imgSrc: Smartfortwo },     
    { id: 16, 
      // name: "Ford Mustang 2017", 
      name: "Ford Mustang", 
      type: "Premium Car", 
      imgSrc: FordMustang },    
  ];